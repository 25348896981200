.rf__accordion-button {
    font-size: 14px;
    background-color: #F3F4F5;
    color: #2A2B2A;
    text-align: left;
    font-weight: 700;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding: 18px 10px;
    cursor: pointer;
    border: none;
    font-family: var(--body-fonts);
    text-transform: uppercase;
    border-radius: 5px;
    margin-bottom: 10px;
  }

  .rf__accordion-list_item {
      margin-bottom: 10px;
  }

  .rf__accordion-list_answer-item {
      padding: 20px;
      /* background-color: #F3F4F5;
      color: #2A2B2A; */
      /* border-top: 0.5px solid #2A2B2A; */
      font-weight: 400;
  }


  /* activate toggle */
.rf__accordion-list_item .active .button {
    background-color: #105057;
  }
  .rf__accordion-list_answer {
    height: 0;
    overflow: hidden;
    transition: height ease 0.2s;
  }
  /* .rf__accordion-list_answer.open {
    height: 100px;
  } */
 