  a { 
    color: unset;
    text-decoration: none;
  }
  
  .rf__hero-grid img {
      width: 100%;
  }
  
  .rf__hero-grid {
      max-width: 1440px;
      display: -moz-flex;
      display: -webkit-flex;
      display: -ms-flex;
      display: flex;
      justify-content: center;
      max-width: 100%;
      width: 100%;
      align-items: end;
      /* -webkit-justify-content: flex-end;
      align-items: flex-end;
      -webkit-align-items: flex-end; */
  }
  
  .rf__hero-grid>div {
      /* border: 1px solid red; */
  }
  
  .rf__robot-2 {
      z-index: 2;
      position: relative;
      margin-left: -3.5%;
  }
  
  .rf__robot-3 {
      z-index: 3;
      position: relative;
      margin-left: -3.5%;
  }
  
  .rf__robot-4 {
      z-index: 3;
      position: relative;
      margin-left: -2.8%;
  }
  
  .rf__robot-5 {
      z-index: 3;
      position: relative;
      margin-left: -1.8%;
  }
  
  .rf__robot-6 {
      z-index: 4;
      position: relative;
      margin-left: -11%;
  }
  
  .rf__robot-7 {
      z-index: 5;
      position: relative;
      margin-left: -4%;
  }

  .rf__container-icon img {
    display: -moz-flex;
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    margin: auto;
  }

  
  .rf__container-icon img {
      max-width: 96.37px;
  }
  
/* Safari 11+ */
@media not all and (min-resolution:.001dpcm)
{ @supports (-webkit-appearance:none) and (stroke-color:transparent) {
  .rf__hero-grid {
    -webkit-justify-content: flex-end;
    align-items: flex-end;
    -webkit-align-items: flex-end;
}
}}
/* Test website on real Safari 11+ */

/* Safari 10.1 */
@media not all and (min-resolution:.001dpcm){ 
@supports (-webkit-appearance:none) and (not (stroke-color:transparent)) {
  .rf__hero-grid {
    -webkit-justify-content: flex-end;
    align-items: flex-end;
    -webkit-align-items: flex-end;
}
}}

/* Safari 6.1-10.0 (but not 10.1) */
@media screen and (min-color-index:0) and(-webkit-min-device-pixel-ratio:0){ 
@media {
  .rf__hero-grid {
    -webkit-justify-content: flex-end;
    align-items: flex-end;
    -webkit-align-items: flex-end;
}
}}


  
  /* Animate  *//* ----------------------------------------------
   * Generated by Animista on 2022-3-30 9:30:13
   * Licensed under FreeBSD License.
   * See http://animista.net/license for more info. 
   * w: http://animista.net, t: @cssanimista
   * ---------------------------------------------- */
  
  /**
   * ----------------------------------------
   * @animation flicker-in-1
   * ----------------------------------------
   */
  
   .flicker-in-1 {
      -webkit-animation: flicker-in-1 1s linear both;
              animation: flicker-in-1 1s linear both;
  }
  
  .flicker-in-2 {
      -webkit-animation: flicker-in-1 2s linear 1s both;
              animation: flicker-in-1 2s linear 1s both;
  }
  
  .flicker-in-3 {
      -webkit-animation: flicker-in-1 3s linear 2s both;
              animation: flicker-in-1 3s linear 2s both;
  }
  
  .flicker-in-4 {
      -webkit-animation: flicker-in-1 3.5s linear 2.5s both;
              animation: flicker-in-1 3.5s linear 2.5s both;
  }
  
  
  /* delay
  animation-delay: 2s; */
  
  @-webkit-keyframes flicker-in-1 {
    0% {
      opacity: 0;
    }
    10% {
      opacity: 0;
    }
    10.1% {
      opacity: 1;
    }
    10.2% {
      opacity: 0;
    }
    20% {
      opacity: 0;
    }
    20.1% {
      opacity: 1;
    }
    20.6% {
      opacity: 0;
    }
    30% {
      opacity: 0;
    }
    30.1% {
      opacity: 1;
    }
    30.5% {
      opacity: 1;
    }
    30.6% {
      opacity: 0;
    }
    45% {
      opacity: 0;
    }
    45.1% {
      opacity: 1;
    }
    50% {
      opacity: 1;
    }
    55% {
      opacity: 1;
    }
    55.1% {
      opacity: 0;
    }
    57% {
      opacity: 0;
    }
    57.1% {
      opacity: 1;
    }
    60% {
      opacity: 1;
    }
    60.1% {
      opacity: 0;
    }
    65% {
      opacity: 0;
    }
    65.1% {
      opacity: 1;
    }
    75% {
      opacity: 1;
    }
    75.1% {
      opacity: 0;
    }
    77% {
      opacity: 0;
    }
    77.1% {
      opacity: 1;
    }
    85% {
      opacity: 1;
    }
    85.1% {
      opacity: 0;
    }
    86% {
      opacity: 0;
    }
    86.1% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes flicker-in-1 {
    0% {
      opacity: 0;
    }
    10% {
      opacity: 0;
    }
    10.1% {
      opacity: 1;
    }
    10.2% {
      opacity: 0;
    }
    20% {
      opacity: 0;
    }
    20.1% {
      opacity: 1;
    }
    20.6% {
      opacity: 0;
    }
    30% {
      opacity: 0;
    }
    30.1% {
      opacity: 1;
    }
    30.5% {
      opacity: 1;
    }
    30.6% {
      opacity: 0;
    }
    45% {
      opacity: 0;
    }
    45.1% {
      opacity: 1;
    }
    50% {
      opacity: 1;
    }
    55% {
      opacity: 1;
    }
    55.1% {
      opacity: 0;
    }
    57% {
      opacity: 0;
    }
    57.1% {
      opacity: 1;
    }
    60% {
      opacity: 1;
    }
    60.1% {
      opacity: 0;
    }
    65% {
      opacity: 0;
    }
    65.1% {
      opacity: 1;
    }
    75% {
      opacity: 1;
    }
    75.1% {
      opacity: 0;
    }
    77% {
      opacity: 0;
    }
    77.1% {
      opacity: 1;
    }
    85% {
      opacity: 1;
    }
    85.1% {
      opacity: 0;
    }
    86% {
      opacity: 0;
    }
    86.1% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
  
  /* ----------------------------------------------
   * Generated by Animista on 2022-3-30 14:20:49
   * Licensed under FreeBSD License.
   * See http://animista.net/license for more info. 
   * w: http://animista.net, t: @cssanimista
   * ---------------------------------------------- */
  
  /**
   * ----------------------------------------
   * @animation flicker-in-1
   * ----------------------------------------
   */
   @-webkit-keyframes flicker-in-2 {
    0% {
      opacity: 0;
    }
    10% {
      opacity: 0;
    }
    10.1% {
      opacity: 1;
    }
    10.2% {
      opacity: 0;
    }
    20% {
      opacity: 0;
    }
    20.1% {
      opacity: 1;
    }
    20.6% {
      opacity: 0;
    }
    30% {
      opacity: 0;
    }
    30.1% {
      opacity: 1;
    }
    30.5% {
      opacity: 1;
    }
    30.6% {
      opacity: 0;
    }
    45% {
      opacity: 0;
    }
    45.1% {
      opacity: 1;
    }
    50% {
      opacity: 1;
    }
    55% {
      opacity: 1;
    }
    55.1% {
      opacity: 0;
    }
    57% {
      opacity: 0;
    }
    57.1% {
      opacity: 1;
    }
    60% {
      opacity: 1;
    }
    60.1% {
      opacity: 0;
    }
    65% {
      opacity: 0;
    }
    65.1% {
      opacity: 1;
    }
    75% {
      opacity: 1;
    }
    75.1% {
      opacity: 0;
    }
    77% {
      opacity: 0;
    }
    77.1% {
      opacity: 1;
    }
    85% {
      opacity: 1;
    }
    85.1% {
      opacity: 0;
    }
    86% {
      opacity: 0;
    }
    86.1% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes flicker-in-2 {
    0% {
      opacity: 0;
    }
    10% {
      opacity: 0;
    }
    10.1% {
      opacity: 1;
    }
    10.2% {
      opacity: 0;
    }
    20% {
      opacity: 0;
    }
    20.1% {
      opacity: 1;
    }
    20.6% {
      opacity: 0;
    }
    30% {
      opacity: 0;
    }
    30.1% {
      opacity: 1;
    }
    30.5% {
      opacity: 1;
    }
    30.6% {
      opacity: 0;
    }
    45% {
      opacity: 0;
    }
    45.1% {
      opacity: 1;
    }
    50% {
      opacity: 1;
    }
    55% {
      opacity: 1;
    }
    55.1% {
      opacity: 0;
    }
    57% {
      opacity: 0;
    }
    57.1% {
      opacity: 1;
    }
    60% {
      opacity: 1;
    }
    60.1% {
      opacity: 0;
    }
    65% {
      opacity: 0;
    }
    65.1% {
      opacity: 1;
    }
    75% {
      opacity: 1;
    }
    75.1% {
      opacity: 0;
    }
    77% {
      opacity: 0;
    }
    77.1% {
      opacity: 1;
    }
    85% {
      opacity: 1;
    }
    85.1% {
      opacity: 0;
    }
    86% {
      opacity: 0;
    }
    86.1% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
  
  /* animate 3 */
  @-webkit-keyframes flicker-in-3 {
    0% {
      opacity: 0;
    }
    10% {
      opacity: 0;
    }
    10.1% {
      opacity: 1;
    }
    10.2% {
      opacity: 0;
    }
    20% {
      opacity: 0;
    }
    20.1% {
      opacity: 1;
    }
    20.6% {
      opacity: 0;
    }
    30% {
      opacity: 0;
    }
    30.1% {
      opacity: 1;
    }
    30.5% {
      opacity: 1;
    }
    30.6% {
      opacity: 0;
    }
    45% {
      opacity: 0;
    }
    45.1% {
      opacity: 1;
    }
    50% {
      opacity: 1;
    }
    55% {
      opacity: 1;
    }
    55.1% {
      opacity: 0;
    }
    57% {
      opacity: 0;
    }
    57.1% {
      opacity: 1;
    }
    60% {
      opacity: 1;
    }
    60.1% {
      opacity: 0;
    }
    65% {
      opacity: 0;
    }
    65.1% {
      opacity: 1;
    }
    75% {
      opacity: 1;
    }
    75.1% {
      opacity: 0;
    }
    77% {
      opacity: 0;
    }
    77.1% {
      opacity: 1;
    }
    85% {
      opacity: 1;
    }
    85.1% {
      opacity: 0;
    }
    86% {
      opacity: 0;
    }
    86.1% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes flicker-in-3 {
    0% {
      opacity: 0;
    }
    10% {
      opacity: 0;
    }
    10.1% {
      opacity: 1;
    }
    10.2% {
      opacity: 0;
    }
    20% {
      opacity: 0;
    }
    20.1% {
      opacity: 1;
    }
    20.6% {
      opacity: 0;
    }
    30% {
      opacity: 0;
    }
    30.1% {
      opacity: 1;
    }
    30.5% {
      opacity: 1;
    }
    30.6% {
      opacity: 0;
    }
    45% {
      opacity: 0;
    }
    45.1% {
      opacity: 1;
    }
    50% {
      opacity: 1;
    }
    55% {
      opacity: 1;
    }
    55.1% {
      opacity: 0;
    }
    57% {
      opacity: 0;
    }
    57.1% {
      opacity: 1;
    }
    60% {
      opacity: 1;
    }
    60.1% {
      opacity: 0;
    }
    65% {
      opacity: 0;
    }
    65.1% {
      opacity: 1;
    }
    75% {
      opacity: 1;
    }
    75.1% {
      opacity: 0;
    }
    77% {
      opacity: 0;
    }
    77.1% {
      opacity: 1;
    }
    85% {
      opacity: 1;
    }
    85.1% {
      opacity: 0;
    }
    86% {
      opacity: 0;
    }
    86.1% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
  
  /* animate 4 */
  @-webkit-keyframes flicker-in-4 {
    0% {
      opacity: 0;
    }
    10% {
      opacity: 0;
    }
    10.1% {
      opacity: 1;
    }
    10.2% {
      opacity: 0;
    }
    20% {
      opacity: 0;
    }
    20.1% {
      opacity: 1;
    }
    20.6% {
      opacity: 0;
    }
    30% {
      opacity: 0;
    }
    30.1% {
      opacity: 1;
    }
    30.5% {
      opacity: 1;
    }
    30.6% {
      opacity: 0;
    }
    45% {
      opacity: 0;
    }
    45.1% {
      opacity: 1;
    }
    50% {
      opacity: 1;
    }
    55% {
      opacity: 1;
    }
    55.1% {
      opacity: 0;
    }
    57% {
      opacity: 0;
    }
    57.1% {
      opacity: 1;
    }
    60% {
      opacity: 1;
    }
    60.1% {
      opacity: 0;
    }
    65% {
      opacity: 0;
    }
    65.1% {
      opacity: 1;
    }
    75% {
      opacity: 1;
    }
    75.1% {
      opacity: 0;
    }
    77% {
      opacity: 0;
    }
    77.1% {
      opacity: 1;
    }
    85% {
      opacity: 1;
    }
    85.1% {
      opacity: 0;
    }
    86% {
      opacity: 0;
    }
    86.1% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes flicker-in-4 {
    0% {
      opacity: 0;
    }
    10% {
      opacity: 0;
    }
    10.1% {
      opacity: 1;
    }
    10.2% {
      opacity: 0;
    }
    20% {
      opacity: 0;
    }
    20.1% {
      opacity: 1;
    }
    20.6% {
      opacity: 0;
    }
    30% {
      opacity: 0;
    }
    30.1% {
      opacity: 1;
    }
    30.5% {
      opacity: 1;
    }
    30.6% {
      opacity: 0;
    }
    45% {
      opacity: 0;
    }
    45.1% {
      opacity: 1;
    }
    50% {
      opacity: 1;
    }
    55% {
      opacity: 1;
    }
    55.1% {
      opacity: 0;
    }
    57% {
      opacity: 0;
    }
    57.1% {
      opacity: 1;
    }
    60% {
      opacity: 1;
    }
    60.1% {
      opacity: 0;
    }
    65% {
      opacity: 0;
    }
    65.1% {
      opacity: 1;
    }
    75% {
      opacity: 1;
    }
    75.1% {
      opacity: 0;
    }
    77% {
      opacity: 0;
    }
    77.1% {
      opacity: 1;
    }
    85% {
      opacity: 1;
    }
    85.1% {
      opacity: 0;
    }
    86% {
      opacity: 0;
    }
    86.1% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
  