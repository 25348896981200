.wrapper {
    position: relative;
    background: url("../../assets/rmbg.svg");
    background-repeat: no-repeat;
    background-size: cover;
}

.rf__container-map_text {
  margin: auto;
  text-align: center;
  padding: 2em 25px 3em 25px;
}

.wrapper {
    position: relative;
  }

  .wrapper .center-line{
    position: absolute;
    height: 100%;
    left: 50%;
    top: 20px;
    transform: translateX(-50%);
  }
  .wrapper .row{
    display: flex;
  }
  .wrapper .row-1{
    justify-content: flex-start;
  }
  .wrapper .row-2{
    justify-content: flex-end;
  }
  .wrapper .row section{
    position: relative;
    background: none;
    color: white;
    border-radius: 5px;
    width: calc(50% - 40px);
    padding: 2em;
    position: relative;
    border: 3px solid #F3F4F5;
    box-sizing: border-box;
    box-shadow: 0px 0px 7px 1px #F3F4F5, 0px 0px 7px 1px #F3F4F5 inset;
  /*  -webkit-box-shadow: 0px 0px 7px 1px #F3F4F5;
    -moz-box-shadow:  0px 0px 7px 1px #F3F4F5;*/
    border-radius: 34px;
  }
  
  .rf__map-list {
    padding-left: 2em;
  }
  
  /*.wrapper .row section::before{
    position: absolute;
    content: "";
    height: 15px;
    width: 15px;
    background: #fff;
    top: 28px;
    z-index: -1;
    transform: rotate(45deg);
  }*/
  .row-1 section::before{
    right: -7px;
  }
  .row-2 section::before{
    left: -7px;
  }
  .row section .icon,
  .center-line .scroll-icon{
    position: absolute;
    background: #f2f2f2;
    height: 40px;
    width: 40px;
    text-align: center;
    line-height: 40px;
    border-radius: 50%;
    color: #ff7979;
    font-size: 17px;
    box-shadow: 0 0 0 4px #fff, inset 0 2px 0 rgba(0,0,0,0.08), 0 3px 0 4px rgba(0,0,0,0.05);
  }
  .center-line .scroll-icon{
    bottom: 0px;
    left: 50%;
    font-size: 25px;
    transform: translateX(-50%);
  }
  .row-1 section .icon{
    top: 15px;
    right: -60px;
  }
  .row-2 section .icon{
    top: 15px;
    left: -60px;
  }
  .row section .details,
  .row section .bottom{
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .row section .details .title{
    font-size: 22px;
    font-weight: 600;
  }
  .row section p{
    margin: 10px 0 17px 0;
  }
  .row section .bottom a{
    text-decoration: none;
    background: none;
    color: white;
    padding: 7px 15px;
    border-radius: 5px;
    /* font-size: 17px; */
    font-weight: 400;
    transition: all 0.3s ease;
  }
  .row section .bottom a:hover{
    transform: scale(0.97);
  }
  @media(max-width: 790px){
    .wrapper .center-line{
     display: none;
    }
    .wrapper .row{
      margin: 30px 0 3px 0px;
    }
    .wrapper .row section{
      width: 100%;
    }
    .row-1 section::before{
      left: -7px;
    }
    .row-1 section .icon{
      left: -60px;
    }
  }
  @media(max-width: 440px){
    .wrapper .center-line,
    .row section::before,
    .row section .icon{
      display: none;
    }
    .wrapper .row{
      margin: 10px 0;
    }
  }