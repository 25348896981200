
p {
    font-family: var(--body-fonts) ;
    font-weight: 300;
    font-size: 14px;
    line-height: 18px;
}

.mint {
    background-color: #749D69 !important;
}

.rf__btn-cta {
    background: #F3F4F5;
    border-radius: 5px;
    color: black;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    padding: 10px 25px;
    margin-top: 25px;
}

.trailer {
    padding: 10px 25px !important;
    border: none;
}

.rf__btn-cta:hover {
    background: red;
}

.rf__container-artwork_column img {
    max-width: 410px;
    width: 100%;
}

.rf__container-artwork {
    position: relative;
    background: url("../../assets/art_bg.svg");
    background-repeat: no-repeat;
    background-size: cover;
}

.rf__container-artwork_row {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 1em 0;
    align-items: center;
    max-width: 1440px;
    margin: auto;
}

.rf__container-artwork_column {
    width: 50%;
}

.rf__container-artwork_column:nth-child(2){
    text-align: center;
}

.rf__container-traits_row {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 1em 0;
}

.rf__container-traits_column {
    padding: 0 1em 0 0;
    width: 50%;
}

.rf__container-artwork_column a:hover {
    /* text-decoration: underline;
    color: red; */
}

@media screen and (max-width: 800px) {
    .rf__container-artwork_row {
        flex-wrap: wrap;
        flex-direction: column;
        -ms-flex-direction: column;
    }  

    .rf__container-artwork_column {
        width: 100%;
    } 
    
    .rf__container-traits_row {
        flex-wrap: wrap;
        flex-direction: column;
        -ms-flex-direction: column;
    }  

    .rf__container-traits_column {
        width: 100%;
    }  
}

    
    