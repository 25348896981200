/* html .rf__swiper-container {
    overflow: hidden;
} */

p {
    font-family: var(--body-fonts) ;
    font-weight: 300;
    font-size: 14px;
    line-height: 18px;
}

.rf__container-brand_column img {
    max-width: 410px;
    margin: auto;
    width: 100%;
}

.rf__container-brand_row {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 1em 0;
    align-items: center;
    max-width: 1440px;
    margin: auto;
}

.rf__container-brand_column {
    width: 50%;
}

.rf__container-brand_column:nth-child(2){
    text-align: center;
}

.rf__swiper-container {
    max-width: 1440px;
    margin: auto;
}

/* swiper */
.swiper-slide {
    width: auto !important;
    overflow-x: hidden;
}

.swiper-slide img {
    height: 360px;
    overflow-x: hidden;
}

@media screen and (max-width: 800px) {
    .rf__container-brand_row {
        flex-wrap: wrap;
        flex-direction: column;
        -ms-flex-direction: column;
    }  

    .rf__container-brand_column {
        width: 100%;
    } 
}

    
    