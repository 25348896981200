.rf__footer-links_container {
    padding: 25px 0;
}

.rf__footer-links_container ul {
    list-style: none;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.rf__footer-container_logo {
   text-align: center;
}

.rf__footer-links_container li,
.rf__footer-social li,
.rf__footer-menu_container li {
    color: #fff;
    font-family: var(--body-fonts);
    font-weight: 400;
    font-size: 12px;
    line-height: 2em;
    text-transform: capitalize;

    margin: 0 1rem;
    cursor: pointer;
}

.rf__footer-copyright {
    text-align: center;
    font-size: 8px;
    line-height: 11px;
    padding: 25px 0;
}

@media screen and (max-width: 600px) {
    .rf__footer-links_container ul {
        display: block;
    }    
    .rf__footer-links_container {
        text-align: center;
    }

}