
.rf__newletter-container {
    justify-content: center;
    align-items: center;
    max-width: 389px;
    margin: auto;
    color: white !important;
}

.rf__newletter-container-copy {
    max-width: 612px;
    margin: auto;   
}

.rf__newletter-content__input > div {
    width: 100%;
    margin: 2rem 0 1rem;
    display: flex;
    display: -webkit-flex;
    display: -ms-flexbox;
    flex-direction: row;
}

.rf__newletter-content__input > div input {
    flex: 2;
    width: 100%;
    padding: 0 1rem;
    border: 2px solid #f3f4f5;
    outline: none;
    color: #F3F4F5;
    background: none;
    min-height: 40px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;  
}

.rf__newletter-content__input > div button {
    flex: 0.7;
    width: 100%;
    font-family: var(--body-fonts);
    border: 2px solid #f3f4f5;
    background-color: #F3F4F5;
    color: black;
    font-weight: bold;
    padding: 0 1rem;
    min-height: 40px;
    cursor: pointer;
    outline: none;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

::placeholder {
    color:  #F3F4F5;
}

@media screen and (min-width: 600px){
    .rf__newletter-container-copy p {
        text-align: center;
    }

    .rf__newletter-container-copy h1 {
        text-align: center;
    }
}
