.rf__container-feelings {
        position: relative;
        background: url("../../assets/feelings_bg.svg");
        background-repeat: no-repeat;
        background-size: cover;
}

.rf__container-feelings_text {
    margin: auto;
    max-width: 850px;
    text-align: center;
    padding: 2em 25px 2em 25px;
}

.rf__feelings-row {
    display: flex;
    justify-content: center;
}

.rf__feelings-row img {
    max-width: 166.33px;
    cursor: pointer;
}

.item {
    position: relative;
}

.item h2 {
    text-align: center;
}

.rf__feelings-row {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.rf__feelings-row div {
    flex: 1;
    max-width: 150px;
    min-width: 120px;
    margin: 1rem;

    justify-content: center;
    align-items: center;
}