.rf__navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;

    padding: 2rem 6rem;
}

.rf__navbar-links {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.rf__navbar-links_logo {
    margin-right: 2rem;
}

.rf__navbar-links_logo img {
}

.rf__navbar-links_container ul {
    list-style: none;
    display: flex;
    flex-direction: row;
}

.rf__navbar-social ul {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    list-style: none;
}

.rf__navbar-links_container li,
.rf__navbar-social li,
.rf__navbar-menu_container li {
    color: #fff;
    font-family: var(--body-fonts);
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    text-transform: capitalize;

    margin: 0 1rem;
    cursor: pointer;
}

.rf__navbar-menu_container-links li {
    font-size: 18px;
}

.rf__navbar-social button,
.rf__navbar-menu_container button {
    /* padding: 1rem 2rem;
    border: 0;
    outline: none;
    cursor: pointer; */
}

.rf__navbar-menu {
    margin-left: 1rem;
    z-index: 100;
    display: none;
    position: relative;
}

.rf__navbar-menu svg {
    cursor: pointer;
}

.rf__navbar-menu_container {
    list-style: none;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: column;
    text-align: end;
    background: #1b1b1be0;
    padding: 2rem;
    position: absolute;
    top: 40px;
    right: 0;
    margin-top: 1rem;
    min-width: 410px;
    border-radius: 5px;
    box-shadow: 0 0 5 rgba(0,0,0,0.2);
}

.rf__navbar-menu_container li {
    margin: 1rem 0;
    list-style: none;
}

.rf__navbar-links_container-links-social {
    display: none;
}

@media screen and (max-width: 1050px) {
    .rf__navbar-links_container {
        display: none;
    }
    .rf__navbar-menu {
        display: flex;
    }

}

@media screen and (max-width: 700px) {
    .rf__navbar {
        padding: 2rem;
    }
    .rf__navbar-social {
        display: none;
    }
    .rf__navbar-menu_container {
        top: 20px;
    }
}