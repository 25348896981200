.rf__container-team {
    /* position: relative;
    background: url("../../assets/feelings_bg.svg");
    background-repeat: no-repeat;
    background-size: cover; */
}

.rf__container-team_text {
    margin: auto;
    text-align: center;
    padding: 2em 25px 3em 25px;
}

.rf__team-row .team_socials {
    margin-left: 0px;
}

.team_socials a img {
    width: 20.1px;
    margin: 0px;
}

.team_socials a {
    margin-right: 8px;
}

.rf__team-row {
    display: flex;
    justify-content: center;
}

.rf__team-row img {
    max-width: 230px;
    width: 100%;
}

.rf__card_team {
    position: relative;
}

.rf__card_team h2 {
    text-align: left;
}

.rf__card_team p {
    text-align: left;
}

.rf__team-row {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.rf__team-row div {
    flex: 1;
    max-width: 230px;
    min-width: 230px;
    margin: 1rem;
    align-self: stretch;
    justify-content: center;
    align-items: center;
}