.rf__accordion-container {
    max-width: 660px;
    margin: auto;
    margin: 6rem auto 4rem;
    padding: 0 10px;
}

.rf__container-faq_text {
    margin: auto;
    text-align: center;
    padding: 2em 25px 3em 25px;
  }